<template>
	<modal-view title="API Token Created Succesfully" :full-width="true" @close="$emit('close')">
		<p>Your new API Token is displayed below. Please copy and save it, since it can't be recovered after you close this window.</p>
		<div id="token">{{token}}</div>
	</modal-view>
</template>

<script>
	import ModalView from '@/components/ModalView';
	
	export default {
		props: {
			token: String
		},
		components: {
			ModalView
		}
	}
</script>

<style scoped lang="scss">
	#token {
		background-color:var(--highlight-color-light);
		padding:30px;
		word-wrap:break-word;
	}
</style>