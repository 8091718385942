<template>
	<h1>Manage API Tokens</h1>
	<div v-if="dataError" class="critical-error">An error has occurred.</div>
	<div v-else-if="apiTokens === null" class="loading-indicator">
		<img src="@/assets/images/loading.gif">
	</div>
	<div v-else>
		<div id="intro">
			This page allows you to view and manage your API Tokens for accessing the Pennytel API.
			<div v-if="apiTokens.length == 0">You don't currently have any API Tokens.</div>
		</div>
		<div id="create-token">
			<div v-if="creatingToken " class="loading-indicator">
				<img src="@/assets/images/loading.gif">
			</div>
			<div v-else id="creation-form" class="form">
				<label for="new-token-name">Create New API Token</label>
				<div>
					<input type="text" placeholder="Token Name" maxlength="100" id="new-token-name" v-model="createTokenName" @input="creationError = ''" />
					<button type="button" @click="createApiToken">Create</button>
				</div>
				<div class="critical-error">{{creationError}}</div>
			</div>
		</div>
		<table v-if="apiTokens.length > 0" id="token-list" class="data-table">
			<tr v-for="(token, index) in apiTokens">
				<td>
					{{token.name}}
					<div v-if="token.deletionError" class="delete-error">An error has occurred.</div>
				</td>
				<td class="delete-cell" @click="deleteApiToken(index)" :class="{deleting: token.deleting}">
					<span v-show="!token.deleting">X</span>
					<img src="@/assets/images/loading.gif" v-show="token.deleting">
				</td>
			</tr>
		</table>
		<new-api-token-confirmation v-if="createdToken != ''" :token="createdToken" @close="createdToken = ''"></new-api-token-confirmation>
	</div>
</template>

<script>
	import NewApiTokenConfirmation from '@/components/Modals/NewApiTokenConfirmation';
	
	export default {
		data() {
			return {
				apiTokens: null,
				dataError: false,
				creatingToken: false,
				createTokenName: '',
				createdToken: '',
				creationError: ''
			}
		},
		components: {
			NewApiTokenConfirmation
		},
		async created() { // When the page is loaded, get the list of API Tokens for the authenticated user.
			await this.loadApiTokens();
		},
		methods: {
			async loadApiTokens(refresh = true) { // Performs the API request to get the list of API Tokens for the authenticated user.
				// If we're visually refreshing the page (rather than stealthily updating the token list), set the token list to NULL to display the loading indicator.
				if(refresh) {
					this.apiTokens = null;
				}
				
				// Perform the API request to get the list of API Tokens for the authenticated user.
				try {
					const response = await this.HTTP.get('user/api-tokens');
					this.apiTokens = response.data.data;
				} catch(error) { // If there was an error obtaining the list of API tokens, display the generic error message.
					this.dataError = true;
				}
			},
			async deleteApiToken(index) { // Deletes the token at the given index in the apiTokens array.
				const token = this.apiTokens[index];
				if(!token.deleting) { // Only continue if this token isn't already in the process of being deleted.
					// Mark the token as being deleted to prevent the user from initiating multiple API calls, and remove the deletion error if it is displayed.
					token.deleting = true;
					token.deletionError = false;
					
					// Perform the API request to delete the given token.
					try {
						await this.HTTP.delete('user/api-tokens/' + token.id);
						this.loadApiTokens(false);
					} catch(error) { // If there was an error deleting the token, display the error message in the table row for the given token.
						token.deleting = false;
						token.deletionError = true;
					}
				}
			},
			async createApiToken() { // Creates a new token for the authenticated user.
				if(this.createTokenName == '') { // If no token name was provided, display an error message.
					this.creationError = 'A token name is required.';
				} else {
					// Replace the token creation form with a loading indicator.
					this.creatingToken = true;
					
					try {
						// Perform the API request to create the token.
						const data = {token_name: this.createTokenName};
						const response = await this.HTTP.post('user/api-tokens', data);
						
						// Display the confirmation popup with the new token, clear the token name from the creation form and display it again, and reload the list of tokens.
						this.createdToken = response.data.data.token;
						this.createTokenName = '';
						this.creatingToken = false;
						this.loadApiTokens(false);
					} catch(error) { // If there was an error creating the token, display an error message below the token creation form.
						this.creatingToken = false;
						this.creationError = 'An error has occurred.';
					}
				}
			}
		}
	}
</script>

<style scoped lang="scss">
	#intro {
		text-align:center;
		margin-bottom:30px;
	}
	
	#create-token {
		text-align:center;
		font-weight:bold;
	}
	
	#creation-form {
		div {
			margin-top:10px;
		}
		
		button {
			margin-left:5px;
		}
	}
	
	#token-list {
		margin-top:50px;
		
		td {
			font-size:2rem;
			
			&.delete-cell {
				img {
					width:20px;
				}
				
				&.deleting {
					cursor:auto;
				}
			}
			
			.delete-error {
				color:#FF0000;
				font-size:1rem;
			}
		}
		
	}
</style>