<template>
	<div>
		<div class="modal" :class="{'full-width': this.fullWidth}">
			<div>
				<h2>{{title}}</h2>
				<slot></slot>
				<div class="close-button" @click="$emit('close')">X</div>
			</div>
		</div>
		<div class="modal-cover"></div>
	</div>
</template>

<script>
	export default {
		props: {
			title: String,
			fullWidth: {
				type: Boolean,
				default: false
			}
		},
		data() {
			return {
				modalHeight: null
			}
		},
		mounted() { // When the modal is first displayed, cache the current modal height, and create the resize observer to monitor for changes to the height.
			const modal = this.getModalReference();
			this.modalHeight = modal.offsetHeight;
			this.preventModalHeightShrink(modal);
		},
		methods: {
			preventModalHeightShrink(modal) { // Creates a resize observer to monitor for changes in the height of the modal and update the minimum height as appropriate.
				const resizeObserver = new ResizeObserver(elements => {
					this.setModalMinHeight(elements[0].target);
				});
				
				resizeObserver.observe(modal);
			},
			setModalMinHeight(modal) { // Checks if the height of the modal is larger than the cached height, and if so, updates the minimum height to the current height.
				const newHeight = modal.offsetHeight;
				if(newHeight > this.modalHeight) {
					modal.style.minHeight = this.getModalHeight(modal);
					this.modalHeight = newHeight;
				}
			},
			getModalReference() { // Gets a reference to the modal element.
				return document.getElementsByClassName('modal')[0].getElementsByTagName('div')[0];
			},
			getModalHeight(modal) { // Returns the height of the modal, including units.
				return window.getComputedStyle(modal).getPropertyValue('height');
			}
		}
	}
</script>

<style scoped lang="scss">
	.modal {
		position:absolute;
		top:50px;
		padding-bottom:50px;
		z-index:1001;
		
		&>div {
			position:relative;
			background-color:#FFFFFF;
			border:2px solid var(--standard-border-color);
			padding:50px;
			height:100%;
		}
		
		&.full-width {
			left:50px;
			right:50px;
		}
		
		&:not(.full-width) {
			left:25%;
			right:25%;
		}
		
		h2 {
			text-align:center;
		}
		
		.close-button {
			position:absolute;
			top:10px;
			right:10px;
			font-weight:bold;
			font-size:2rem;
			cursor:pointer;
		}
	}
	
	.modal-cover {
		position:fixed;
		top:0;
		bottom:0;
		left:0;
		right:0;
		z-index:1000;
		background-color:var(--highlight-color-light);
		opacity:50%;
	}
</style>